import React, { ClipboardEvent, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doLogin } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import * as S from './LoginForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { ReactComponent as Logo } from '@app/assets/logo.svg';
import { Form, Space } from 'antd';
import { InputPassword } from '@app/components/common/inputs/InputPassword/InputPassword.styles';
import { deleteToken, readUser } from '@app/services/sessionStorage.service';
import { useTranslation } from 'react-i18next';
import { Input } from '@app/components/common/inputs/BaseInput/BaseInput.styles';
import logo from 'assets/logo-orange.png';

interface LoginFormData {
  email: string;
  password: string;
}

export const initValues: LoginFormData = {
  email: '',
  password: '',
};

export const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);

  const handleOnPaste = (e: React.ClipboardEvent) => {
    form.setFieldValue('password', e.clipboardData.getData('text').trim());
    e.preventDefault();
  };

  const handleSubmit = (values: LoginFormData) => {

    deleteToken();

    setLoading(true);
    dispatch(doLogin(values))
      .unwrap()
      .then(() => {
        navigate('/home')
        window.location.reload();
      })
      .catch((err: Error) => {
        console.log('json error', err);
        console.error(err.message);
        if (err.message && err.message.includes('401')) {
          notificationController.error({ message: t('auth.logInMessageErro') });
        }
        else {
          notificationController.error({ message: t('auth.logInMaintainance') });
        }

        setLoading(false);
      });
  };

  return (
    <Auth.FormWrapper>
      <img src={logo} alt="HagahNet" height={250} style={{ marginBottom: '4rem', minWidth: '2rem' }} />
      <BaseForm form={form} layout="vertical" onFinish={handleSubmit}>
        <Auth.FormTitle>{t('common.loginMsg')}</Auth.FormTitle>
        <Auth.FormDescription>{t('common.loginSubMsg')}</Auth.FormDescription>
        <Form.Item
          name="email"
          rules={[
            { required: true, message: t('common.fieldRequired') },
            {
              type: 'email',
              message: t('common.notValidEmail'),
            },
          ]}
          label={t('common.user')}
        >
          <Input placeholder={t('common.userPlaceholder')} />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: t('common.fieldRequired') }]}
          label={t('common.password')}
        >
          <InputPassword type="password" placeholder={t('common.passwordPlaceholder')} onPaste={handleOnPaste} />
        </Form.Item>

        {/* <Auth.ActionsWrapper>
          <Link to="/auth/forgot-password">
            <S.ForgotPasswordText>Esqueceu sua senha?</S.ForgotPasswordText>
          </Link>
        </Auth.ActionsWrapper> */}
        <BaseForm.Item noStyle>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
              Entrar
            </Auth.SubmitButton>
          </Space>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};