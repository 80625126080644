import { LoginModel } from '@app/domain/interfaces/auth/LoginModel';
import { ApiServiceBase, IApiServiceBase } from './apiServiceBase';
import { LoginResponseModel } from '@app/domain/interfaces/auth/LoginResponseModel';
import axios from 'axios';

interface AuthModel {}

export default interface IAuthService extends IApiServiceBase<AuthModel, AuthModel> {
  login(model: LoginModel): Promise<LoginResponseModel>;
}

export class AuthService extends ApiServiceBase<AuthModel, AuthModel> implements IAuthService {
  constructor() {
    super('');
  }

  login = (loginPayload: LoginModel): Promise<LoginResponseModel> => {
    return axios
      .create()
      .post<LoginResponseModel>(`${process.env.REACT_APP_BASE_URL}/connect/token`, {
        grantType: 'TOKEN',
        refreshToken: '',
        username: loginPayload.email,
        password: loginPayload.password,
      })
      .then(({ data }) => data);
  };
}
