import React from 'react';
import {
  HomeOutlined,
  BookOutlined,
  ContactsOutlined,
  IdcardOutlined,
  TeamOutlined,
  ShoppingOutlined,
  BlockOutlined,
  CalendarOutlined,
  LaptopOutlined,
  FileZipOutlined,
  DollarOutlined,
  PaperClipOutlined,
  UserOutlined,
  UserDeleteOutlined,
  ShopOutlined,
  SolutionOutlined,
} from '@ant-design/icons';
import { ProjectOutlined } from '@ant-design/icons/lib';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  profiles: string;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'navBar.home',
    key: 'home',
    // TODO use path variable
    url: '/home',
    icon: <HomeOutlined />,
    profiles: "Administrative|Sales|Supervisor|Operational|Secretary|Designer"
  },
  {
    title: 'project.title',
    key: 'projects',
    // TODO use path variable
    url: '/projects',
    icon: <LaptopOutlined />,
    profiles: "Administrative|Sales|Supervisor|Secretary|Designer"
  },
  {
    title: 'calendar.title',
    key: 'calendar',
    // TODO use path variable
    url: '/calendar',
    icon: <CalendarOutlined />,
    profiles: "Administrative|Sales|Supervisor|Operational|Secretary|Designer"
  },
  {
    title: 'navBar.financialNavBar',
    key: 'finances',
    icon: <DollarOutlined />,
    profiles: "Administrative|Secretary",
    children: [
      {
        title: 'navBar.financialNavBar',
        key: 'finances',
        url: '/finances',
        profiles: "Administrative|Secretary"
      },
      {
        title: 'navBar.categoriesNavBar',
        key: 'financescategories',
        url: '/finances/categories',
        profiles: "Administrative|Secretary"
      },
      {
        title: 'navBar.subCategoriesNavBar',
        key: 'subcategories',
        url: '/finances/subcategories',
        profiles: "Administrative|Secretary"
      },
    ],
  },
  {
    title: 'navBar.leadNavBar',
    key: 'leads',
    // TODO use path variable
    url: '/leads',
    icon: <UserOutlined />,
    profiles: "Administrative|Sales|Supervisor|Secretary|Designer"
  },
  {
    title: 'navBar.customerNavBar',
    key: 'customers',
    // TODO use path variable
    url: '/customers',
    icon: <SolutionOutlined />,
    profiles: "Administrative|Sales|Supervisor|Secretary|Designer"
  },
  {
    title: 'navBar.claimNavBar',
    key: 'claims',
    // TODO use path variable
    url: '/claims',
    icon: <PaperClipOutlined />,
    profiles: "Administrative|Sales|Supervisor|Operational|Secretary|Designer"
  },
  {
    title: 'navBar.accountNavBar',
    key: 'suppliers',
    // TODO use path variable
    url: '/suppliers',
    icon: <ShopOutlined />,
    profiles: "Administrative|Sales|Supervisor|Secretary|Designer"
  },
  {
    title: 'navBar.productNavBar',
    key: 'auth',
    icon: <ShoppingOutlined />,
    profiles: "Administrative|Sales|Supervisor|Secretary|Designer",
    children: [
      {
        title: 'navBar.productNavBar',
        key: 'products',
        url: '/products',
        profiles: "Administrative|Sales|Supervisor|Secretary|Designer",
      },
      {
        title: 'navBar.categoriesNavBar',
        key: 'categories',
        url: '/categories',
        profiles: "Administrative|Sales|Supervisor|Secretary|Designer",
      },
      {
        title: 'navBar.subCategoriesNavBar',
        key: 'subcategories',
        url: '/subcategories',
        profiles: "Administrative|Sales|Supervisor|Secretary|Designer",
      },
    ],
  },
  {
    title: 'project.filed.lead.title',
    key: 'projects-lead',
    // TODO use path variable
    url: '/projects/lead',
    icon: <ProjectOutlined />,
    profiles: "Administrative|Sales|Supervisor|Secretary|Designer"
  },  
  {
    title: 'project.filed.title',
    key: 'projects-filed',
    // TODO use path variable
    url: '/projects-filed',
    icon: <FileZipOutlined />,
    profiles: "Administrative|Sales|Supervisor|Secretary|Designer"
  },  
  {
    title: 'lead.filed.title',
    key: 'leads-filed',
    // TODO use path variable
    url: '/leads-filed',
    icon: <UserDeleteOutlined />,
    profiles: "Administrative|Sales|Supervisor|Secretary|Designer"
  },  
  {
    title: 'navBar.employeeNavBar',
    key: 'employees',
    // TODO use path variable
    url: '/employees',
    icon: <IdcardOutlined />,
    profiles: "Administrative"
  }, 
  {
    title: 'navBar.userNavBar',
    key: 'users',
    url: '/users',
    icon: <TeamOutlined />,
    profiles: "Administrative"
  },
  
  // {
  //   title: 'common.charts',
  //   key: 'charts',
  //   url: '/charts',
  //   icon: <LineChartOutlined />,
  // },
  // {
  //   title: 'common.maps',
  //   key: 'maps',
  //   icon: <CompassOutlined />,
  //   children: [
  //     {
  //       title: 'common.googleMap',
  //       key: 'google-maps',
  //       url: '/maps/google-maps',
  //     },
  //   ],
  // },
  // {
  //   title: 'common.pages',
  //   key: 'pages',
  //   icon: <LayoutOutlined />,
  //   children: [
  //     {
  //       title: 'common.profilePage',
  //       key: 'profile',
  //       url: '/profile',
  //     },
  //     {
  //       title: 'common.serverError',
  //       key: 'serverError',
  //       url: '/server-error',
  //     },
  //     {
  //       title: 'common.clientError',
  //       key: '404Error',
  //       url: '/404',
  //     },
  //   ],
  // },
  // {
  //   title: 'common.ui',
  //   key: 'ui',
  //   icon: <BlockOutlined />,
  //   children: [
  //     {
  //       title: 'common.alert',
  //       key: 'alert',
  //       url: '/ui-components/alert',
  //     },
  //     {
  //       title: 'common.avatar',
  //       key: 'avatar',
  //       url: '/ui-components/avatar',
  //     },
  //     {
  //       title: 'common.autocomplete',
  //       key: 'auto-complete',
  //       url: '/ui-components/auto-complete',
  //     },
  //     {
  //       title: 'common.badge',
  //       key: 'badge',
  //       url: '/ui-components/badge',
  //     },
  //     {
  //       title: 'common.breadcrumbs',
  //       key: 'breadcrumbs',
  //       url: '/ui-components/breadcrumbs',
  //     },
  //     {
  //       title: 'common.button',
  //       key: 'button',
  //       url: '/ui-components/button',
  //     },
  //     {
  //       title: 'common.checkbox',
  //       key: 'checkbox',
  //       url: '/ui-components/checkbox',
  //     },
  //     {
  //       title: 'common.collapse',
  //       key: 'collapse',
  //       url: '/ui-components/collapse',
  //     },
  //     {
  //       title: 'common.dateTimePicker',
  //       key: 'dateTimePicker',
  //       url: '/ui-components/date-time-picker',
  //     },
  //     {
  //       title: 'common.dropdown',
  //       key: 'dropdown',
  //       url: '/ui-components/dropdown',
  //     },
  //     {
  //       title: 'common.input',
  //       key: 'input',
  //       url: '/ui-components/input',
  //     },
  //     {
  //       title: 'common.modal',
  //       key: 'modal',
  //       url: '/ui-components/modal',
  //     },
  //     {
  //       title: 'common.notification',
  //       key: 'notification',
  //       url: '/ui-components/notification',
  //     },
  //     {
  //       title: 'common.pagination',
  //       key: 'pagination',
  //       url: '/ui-components/pagination',
  //     },
  //     {
  //       title: 'common.popconfirm',
  //       key: 'popconfirm',
  //       url: '/ui-components/popconfirm',
  //     },
  //     {
  //       title: 'common.popover',
  //       key: 'popover',
  //       url: '/ui-components/popover',
  //     },
  //     {
  //       title: 'common.progress',
  //       key: 'progress',
  //       url: '/ui-components/progress',
  //     },
  //     {
  //       title: 'common.radio',
  //       key: 'radio',
  //       url: '/ui-components/radio',
  //     },
  //     {
  //       title: 'common.rate',
  //       key: 'rate',
  //       url: '/ui-components/rate',
  //     },
  //     {
  //       title: 'common.result',
  //       key: 'result',
  //       url: '/ui-components/result',
  //     },
  //     {
  //       title: 'common.select',
  //       key: 'select',
  //       url: '/ui-components/select',
  //     },
  //     {
  //       title: 'common.skeleton',
  //       key: 'skeleton',
  //       url: '/ui-components/skeleton',
  //     },
  //     {
  //       title: 'common.spinner',
  //       key: 'spinner',
  //       url: '/ui-components/spinner',
  //     },
  //     {
  //       title: 'common.steps',
  //       key: 'steps',
  //       url: '/ui-components/steps',
  //     },
  //     {
  //       title: 'common.switch',
  //       key: 'switch',
  //       url: '/ui-components/switch',
  //     },
  //     {
  //       title: 'common.tabs',
  //       key: 'tabs',
  //       url: '/ui-components/tabs',
  //     },
  //     {
  //       title: 'common.upload',
  //       key: 'upload',
  //       url: '/ui-components/upload',
  //     },
  //   ],
  // },
];
