import React, { useEffect, useState } from 'react';
import {
  BellOutlined,
  ExclamationCircleTwoTone,
  WarningTwoTone,
  QuestionCircleTwoTone,
  NotificationOutlined,
  CloseCircleOutlined,
  SmileTwoTone,
  CheckCircleTwoTone,
  FireTwoTone,
  EyeOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { HeaderActionWrapper } from '@app/components/header/Header.styles';
import { BasePopover } from '@app/components/common/BasePopover/BasePopover';
import { Avatar, Badge, Button, Card, Checkbox, Col, Divider, Form, List, Row, Skeleton } from 'antd';
import INotificationService, { NotificationService } from '@app/services/notificationService';
import { NotificationListModel } from '@app/domain/interfaces/notification/NotificationListModel';
import InfiniteScroll from 'react-infinite-scroll-component';
import { notificationController } from '@app/controllers/notificationController';
import { useTranslation } from 'react-i18next';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import { LKCModal } from '@app/components/modal/Modal';
import Icon from '@ant-design/icons/lib/components/Icon';

const notificationService: INotificationService = new NotificationService();

export const NotificationDropdown: React.FC = () => {
  const [isOpenedPopUp, setOpenPopUp] = useState(false);
  const [listNotification, setListNotification] = useState<NotificationListModel[]>([]);
  const [listNotificationAll, setListNotificationAll] = useState<NotificationListModel[]>([]);
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [readAllSelected, setReadAllSelected] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const response: any = await notificationService.getFalse();
      if (response) {
        const quantidadeNaoVizualizada = response.filter((x: { visualized: boolean }) => x.visualized === false).length;
        setCount(quantidadeNaoVizualizada);
        setListNotification(response);

        /*const interval = setInterval(() => {
          setListNotification(response);
        }, 300000);*/
        //return () => clearInterval(interval);
      }
    })();
  }, []);

  const loadMoreData = async () => {
    const response: any = await notificationService.getFalse();
    setListNotification(response);
  };

  const handleVisualized = async (id: string) => {
    const response: any = await notificationService.customizedPut(id);

    if (response === 200) {
      //const responseNotification: any = await notificationService.getFalse();
      var newList: NotificationListModel[] = listNotification.map((x) => {
        if (x.id === id) {
          x.visualized = true;
        }
        return x;
      });
      const quantidadeNaoVizualizada = newList.filter((x: { visualized: boolean }) => x.visualized === false).length;
      setCount(quantidadeNaoVizualizada);
      //notificationController.success({ message: t('msg.successReadNotification') });
      setListNotification(newList);
    } else {
      notificationController.error({ message: t('msg.erroReadNotification') });
    }
  };

  const handleAllVisualized = async () => {

    if(!readAllSelected) return;

    const response: any = await notificationService.readAll();

    if (response === 204) {
      setReadAllSelected(false);
      
      var newList: NotificationListModel[] = listNotification.map((x) => {        
          x.visualized = true;
        return x;
      });
      
      const quantidadeNaoVizualizada = newList.filter((x: { visualized: boolean }) => x.visualized === false).length;
      setCount(quantidadeNaoVizualizada);
      //notificationController.success({ message: t('msg.successReadNotification') });
      setListNotification(newList);
    } else {
      notificationController.error({ message: t('msg.erroReadNotification') });
    }
  };

  const onClose = () => {
    setOpen(false);
    setOpenPopUp(false);
  };

  const onOpen = async () => {
    setOpen(true);
    setLoading(true);
    setOpenPopUp(false);
    const response: any = await notificationService.getAll();
    if (response.status === 200) {
      setLoading(false);
      setListNotificationAll(response.data);
    }
  };

  return (
    <BasePopover
      content={
        <div
          id="scrollableDiv"
          style={{
            height: 530,
            width: 450,
            overflow: 'auto',
            padding: '0 16px',
            border: '1px solid rgba(140, 140, 140, 0.35)',
          }}
        >
          <InfiniteScroll
            dataLength={listNotification.length}
            next={loadMoreData}
            hasMore={false}
            loader={<Skeleton avatar paragraph={{ rows: 1 }} active={false} />}
            endMessage={
              <>
                <div style={{ marginBottom: '30px', marginTop: '30px', display: 'flex', justifyContent: 'center' }}>
                  <Button type="primary" ghost size="small" onClick={onOpen}>
                    {t('buttons.allNotifications')} <NotificationOutlined />
                  </Button>
                </div>
              </>
            }
            scrollableTarget="scrollableDiv"
          >
            <div style={{ marginTop: '10px', marginBottom: '30px' }}>
              <Checkbox disabled={count === 0} onChange={() => setReadAllSelected(!readAllSelected)}>Read all?</Checkbox>
              <Button type='primary' disabled={readAllSelected === false} size='small' style={{ marginLeft: '10px', float: 'right' }} onClick={handleAllVisualized}>Read all submit</Button>
            </div>
            <List
              dataSource={listNotification.sort((a, b) => (a.visualized ? 1 : 0) - (b.visualized ? 1 : 0))}
              renderItem={(item) => (
                <List.Item
                  key={item.id}
                  style={{
                    background: item.visualized === false ? '#fafafa' : '#ffffff',
                    borderRadius: item.visualized === false ? '10px' : '0px',
                    margin: '10px',
                  }}
                >
                  <List.Item.Meta
                    avatar={
                      item.type.name === 'Information' ? (
                        <ExclamationCircleTwoTone
                          twoToneColor={item.type.color}
                          style={{ fontSize: '30px', marginLeft: '8px' }}
                        />
                      ) : item.type.name === 'Warning' ? (
                        <WarningTwoTone
                          twoToneColor={item.type.color}
                          style={{ fontSize: '30px', marginLeft: '8px' }}
                        />
                      ) : item.type.name === 'Danger' ? (
                        <FireTwoTone twoToneColor={item.type.color} style={{ fontSize: '30px', marginLeft: '8px' }} />
                      ) : (
                        <QuestionCircleTwoTone
                          twoToneColor={item.type.color}
                          style={{ fontSize: '30px', marginLeft: '8px' }}
                        />
                      )
                    }
                    title={
                      <>
                        <h3 style={{ cursor: 'pointer' }} onClick={() => handleVisualized(item.id)}>
                          {item.title}
                        </h3>
                      </>
                    }
                    description={
                      <>
                        <Row>
                          <Col span={item.visualized ? 24 : 20}>
                            <p
                              style={{ cursor: 'pointer', fontSize: '15px' }}
                              onClick={() => handleVisualized(item.id)}
                            >
                              {item.message}
                            </p>
                            <p style={{ fontSize: '15px' }}>
                              {new Date(item.timestamp).toLocaleString('en-US', {
                                hour12: true,
                                dateStyle: 'short',
                                timeStyle: 'short',
                              })}
                            </p>
                            {item.visualized && (
                              <p
                                style={{
                                  fontSize: '13px',
                                  fontStyle: 'italic',
                                  textAlign: 'right',
                                  verticalAlign: 'bottom',
                                  bottom: '0px',
                                }}
                              >
                                {new Date(item.timestamp).toLocaleString('en-US', {
                                  hour12: true,
                                  dateStyle: 'short',
                                  timeStyle: 'short',
                                })}
                                <CheckOutlined style={{ marginLeft: '5px', fontSize: '10px' }} />
                                <CheckOutlined style={{ fontSize: '10px' }} />
                              </p>
                            )}
                          </Col>
                          {!item.visualized && (
                            <Col span={4} style={{ textAlign: 'center' }}>
                              <EyeOutlined
                                style={{ fontSize: '24px', cursor: 'pointer' }}
                                onClick={() => handleVisualized(item.id)}
                              />
                            </Col>
                          )}
                        </Row>
                      </>
                    }
                  />
                </List.Item>
              )}
            />
          </InfiniteScroll>
        </div>
      }
      trigger="click"
      onOpenChange={setOpenPopUp}
      open={isOpenedPopUp}
    >
      <HeaderActionWrapper>
        <BaseButton
          icon={
            <Badge count={count}>
              <BellOutlined />
            </Badge>
          }
          type={isOpenedPopUp ? 'text' : 'text'}
        />
      </HeaderActionWrapper>
      <LKCModal
        title={t('modal.titleModalNotification')}
        onClose={onClose}
        open={open}
        closeIcon={<CloseCircleOutlined />}
        footer={null}
      >
        <BaseSpin spinning={loading}>
          <>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" xs={24} sm={24} md={12} lg={12}>
                <div
                  id="scrollableDiv"
                  style={{
                    height: 500,
                    width: 900,
                    overflow: 'auto',
                    padding: '0 16px',
                    border: '1px solid rgba(140, 140, 140, 0.35)',
                  }}
                >
                  <InfiniteScroll
                    dataLength={listNotificationAll.length}
                    next={loadMoreData}
                    hasMore={false}
                    loader={<Skeleton avatar paragraph={{ rows: 1 }} active={false} />}
                    endMessage={
                      <>
                        <h2 style={{ marginBottom: '30px', display: 'flex', justifyContent: 'center' }}>
                          {t('modal.footerModalNotification')} &nbsp;&nbsp;
                          <CheckCircleTwoTone />
                        </h2>
                      </>
                    }
                    scrollableTarget="scrollableDiv"
                  >
                    <List
                      dataSource={listNotificationAll}
                      renderItem={(item) => (
                        <List.Item key={item.id}>
                          <List.Item.Meta
                            avatar={
                              item.type.name === 'Information' ? (
                                <ExclamationCircleTwoTone
                                  twoToneColor={item.type.color}
                                  style={{ fontSize: '30px', marginLeft: '8px' }}
                                />
                              ) : item.type.name === 'Warning' ? (
                                <WarningTwoTone
                                  twoToneColor={item.type.color}
                                  style={{ fontSize: '30px', marginLeft: '8px' }}
                                />
                              ) : item.type.name === 'Danger' ? (
                                <FireTwoTone
                                  twoToneColor={item.type.color}
                                  style={{ fontSize: '30px', marginLeft: '8px' }}
                                />
                              ) : (
                                <QuestionCircleTwoTone
                                  twoToneColor={item.type.color}
                                  style={{ fontSize: '30px', marginLeft: '8px' }}
                                />
                              )
                            }
                            title={
                              <>
                                <h3>{item.title}</h3>
                              </>
                            }
                            description={
                              <>
                                <p>{item.message}</p>
                                <p>
                                  {new Date(item.timestamp).toLocaleString('en-US', {
                                    hour12: true,
                                    dateStyle: 'short',
                                    timeStyle: 'short',
                                  })}
                                </p>
                              </>
                            }
                          />
                        </List.Item>
                      )}
                    />
                  </InfiniteScroll>
                </div>
              </Col>
            </Row>
            <Divider orientation="left"></Divider>
          </>
        </BaseSpin>
      </LKCModal>
    </BasePopover>
  );
};
