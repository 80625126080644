import React from 'react';
import * as S from './ProfileInfo.styles';
import InitialsAvatar from 'react-initials-avatar';
import 'react-initials-avatar/lib/ReactInitialsAvatar.css';
import { UserAuthModel } from '@app/domain/interfaces/auth/UserAuthModel';

interface ProfileInfoProps {
  profileData: UserAuthModel | null;
}

export const ProfileInfo: React.FC<ProfileInfoProps> = ({ profileData }) => {
  return profileData ? (
    <S.Wrapper>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '1rem',
        }}
      >
        <InitialsAvatar name={profileData?.name} className="avatar" />
      </div>
      <S.Title>{`${profileData?.name}`}</S.Title>
      <S.Subtitle>{profileData?.profile}</S.Subtitle>
    </S.Wrapper>
  ) : null;
};
