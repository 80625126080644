import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import ErroDashboard from '@app/pages/modules/ErroPage/ErroDashboard';

const DataTablesPage = React.lazy(() => import('@app/pages/DataTablesPage'));
const HomePage = React.lazy(() => import('@app/pages/modules/Home/HomeDashboard'));
const LeadsProjectPage = React.lazy(() => import('@app/pages/modules/LeadsProject/LeadsProject'));
const LeadsProjectLeadPage = React.lazy(() => import('@app/pages/modules/LeadsProject/LeadsProject'));
const LeadsProjectFiledPage = React.lazy(() => import('@app/pages/modules/LeadsProject/LeadsProjectFiled'));
const CalendarPage = React.lazy(() => import('@app/pages/modules/Calendar/CalendarDashboard'));
const UsersPage = React.lazy(() => import('@app/pages/modules/Users/UserDashboard'));
const AccountsPage = React.lazy(() => import('@app/pages/modules/Accounts/AccountsDashboard'));
const EmployeesPage = React.lazy(() => import('@app/pages/modules/Employees/EmployeesDashboard'));
const LeadsPage = React.lazy(() => import('@app/pages/modules/Leads/LeadsDashboard'));
const CustomersPage = React.lazy(() => import('@app/pages/modules/Customers/CustomerDashboard'));
const ErroPage = React.lazy(() => import('@app/pages/modules/ErroPage/ErroDashboard'));
const Erro401 = React.lazy(() => import('@app/pages/modules/ErroPage/Erro401'));
const Erro403 = React.lazy(() => import('@app/pages/modules/ErroPage/Erro403'));
const Erro404 = React.lazy(() => import('@app/pages/modules/ErroPage/Erro404'));
const Erro500 = React.lazy(() => import('@app/pages/modules/ErroPage/Erro500'));
const ClaimsPage = React.lazy(() => import('@app/pages/modules/Claims/ClaimsDashboard'));
const ProductsPage = React.lazy(() => import('@app/pages/modules/Products/ProductsDashboard'));
const CategoriesPage = React.lazy(() => import('@app/pages/modules/Categories/CategoriesDashboard'));
const SubCategoriesPage = React.lazy(() => import('@app/pages/modules/SubCategories/SubCategoriesDashboard'));
const ProjectPage = React.lazy(() => import('@app/pages/modules/Project/ProjectCreate'));
const DashboardPage = React.lazy(() => import('@app/pages/modules/Dashboards/GraphDashboard'));
const FinancialPage = React.lazy(() => import('@app/pages/modules/Financial/FinancialDashboard'));
const FinancialCategoryPage = React.lazy(
  () => import('@app/pages/modules/Financial/FinancialCategories/FinancialCategoriesDashboard'),
);
const FinancialSubcategoryPage = React.lazy(
  () => import('@app/pages/modules/Financial/FinancialSubcategories/FinancialSubcategoriesDashboard'),
);
const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const PersonalInfoPage = React.lazy(() => import('@app/pages/PersonalInfoPage'));
const SecuritySettingsPage = React.lazy(() => import('@app/pages/SecuritySettingsPage'));
const Logout = React.lazy(() => import('./Logout'));

export const HOME_PATH = '/';

const DataTables = withLoading(DataTablesPage);
const Home = withLoading(HomePage);
const LeadsProject = withLoading(LeadsProjectPage);
const LeadsProjectLead = withLoading(LeadsProjectLeadPage);
const LeadsProjectFiled = withLoading(LeadsProjectFiledPage);
const Calendar = withLoading(CalendarPage);
const Users = withLoading(UsersPage);
const Accounts = withLoading(AccountsPage);
const Employees = withLoading(EmployeesPage);
const Leads = withLoading(LeadsPage);
const Customer = withLoading(CustomersPage);
const ErrosDashboard = withLoading(ErroPage);
const Erros401 = withLoading(Erro401);
const Erros403 = withLoading(Erro403);
const Erros404 = withLoading(Erro404);
const Erros500 = withLoading(Erro500);
const Claims = withLoading(ClaimsPage);
const Products = withLoading(ProductsPage);
const Categories = withLoading(CategoriesPage);
const SubCategories = withLoading(SubCategoriesPage);
const Projects = withLoading(ProjectPage);
const Dashboards = withLoading(DashboardPage);
const Finances = withLoading(FinancialPage);
const FinancesCategory = withLoading(FinancialCategoryPage);
const FinancesSubcategory = withLoading(FinancialSubcategoryPage);
const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

// Profile
const PersonalInfo = withLoading(PersonalInfoPage);
const SecuritySettings = withLoading(SecuritySettingsPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={HOME_PATH} element={protectedLayout}>
          <Route path="home" element={<Dashboards />} />
          <Route path="projects" element={<LeadsProject />}  />
          <Route path="projects/:status" element={<LeadsProjectLead />}  />
          <Route path="calendar" element={<Calendar />} />
          <Route path="finances" element={<Finances />} />
          <Route path="finances/categories" element={<FinancesCategory />} />
          <Route path="finances/subcategories" element={<FinancesSubcategory />} />
          <Route path="leads" element={<Leads />} />
          <Route path="customers" element={<Customer />} />
          <Route path="error" element={<ErrosDashboard />} />
          <Route path="401" element={<Erros401 />} />
          <Route path="403" element={<Erros403 />} />
          <Route path="404" element={<Erros404 />} />
          <Route path="500" element={<Erros500 />} />
          <Route path="claims" element={<Claims />} />
          <Route path="suppliers" element={<Accounts />} />
          <Route path="products" element={<Products />} />
          <Route path="categories" element={<Categories />} />
          <Route path="subcategories" element={<SubCategories />} />
          <Route path="projects-filed" element={<LeadsProjectFiled />} />
          <Route path="leads-filed" element={<Leads />} />
          <Route path="dashboards" element={<Dashboards />} />
          <Route path="employees" element={<Employees />} />
          <Route path="users" element={<Users />} />
          <Route path="/project/:projecId" element={<Projects />} />
          <Route path="server-error" element={<ServerError />} />
          <Route path="profile" element={<ProfileLayout />}>
            <Route path="personal-info" element={<PersonalInfo />} />
            <Route path="security-settings" element={<SecuritySettings />} />
          </Route>
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};
