import { NotificationCreateUpdateModel } from '@app/domain/interfaces/notification/NotificationCreateUpdateModel';
import { ApiServiceBase, IApiServiceBase } from './apiServiceBase';
import axiosService from './axiosService';
import { FinancialCreateUpdateModel } from '@app/domain/interfaces/financial/FinancialCreateUpdateModel';
import {
  NotificationListArrayModel,
  NotificationListModel,
} from '@app/domain/interfaces/notification/NotificationListModel';

export default interface INotificationService extends IApiServiceBase<NotificationListModel, NotificationListModel> {
  getFalse(): Promise<NotificationListArrayModel>;
  getAll(): Promise<NotificationListArrayModel>;
  customizedPut(id: string): Promise<NotificationListModel>;
  readAll(): Promise<NotificationListModel>;
}

export class NotificationService
  extends ApiServiceBase<NotificationListModel, NotificationListModel>
  implements INotificationService
{
  constructor() {
    super('v1/notification');
  }

  getFalse = async (): Promise<NotificationListArrayModel> => {
    try {
      const response = await axiosService.get(`v1/notification`);
      return response?.data;
    } catch (error) {
      throw error;
    }
  };

  getAll = async (): Promise<NotificationListArrayModel> => {
    try {
      const response: any = await axiosService.get(`v1/notification/all`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  customizedPut = async (id: string): Promise<NotificationListModel> => {
    try {
      const response: any = await axiosService.put(`v1/notification/${id}`);
      return response?.status;
    } catch (error) {
      throw error;
    }
  };

  readAll = async (): Promise<NotificationListModel> => {
    try {
      const response: any = await axiosService.put(`v1/notification/read-all`);
      return response?.status;
    } catch (error) {
      throw error;
    }
  };
}
