import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setUser } from '@app/store/slices/userSlice';
import { deleteToken, deleteUser, persistToken, readToken } from '@app/services/sessionStorage.service';
import IAuthService, { AuthService } from '@app/services/authService';
import { LoginModel } from '@app/domain/interfaces/auth/LoginModel';

export interface AuthSlice {
  token: string | null;
}

const initialState: AuthSlice = {
  token: readToken(),
};

const authService: IAuthService = new AuthService();

export const doLogin = createAsyncThunk('auth/doLogin', async (loginPayload: LoginModel, { dispatch }) =>
  authService.login(loginPayload).then((res) => {
    dispatch(setUser(res.userInfo));
    persistToken(res.accessToken);

    return res.accessToken;
  }),
);

export const doLogout = createAsyncThunk('auth/doLogout', (payload, { dispatch }) => {
  deleteToken();
  deleteUser();
  dispatch(setUser(null));
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.token = action.payload;
    });
    builder.addCase(doLogout.fulfilled, (state) => {
      state.token = '';
    });
  },
});

export default authSlice.reducer;
