import React from 'react';
import * as S from './Modal.styles';
import { BaseCard } from '../common/BaseCard/BaseCard';
import Drawer from 'antd/lib/drawer';

interface ILKCModal {
  title: string;
  closeIcon: React.ReactNode;
  onClose: () => void;
  open: boolean;
  footer?: React.ReactNode;
  children: React.ReactNode;
  maxWidth?: string;
}

export const LKCModal: React.FC<ILKCModal> = ({ title, closeIcon, onClose, open, footer, maxWidth, children }) => {
  return (
    <>
      <S.Modal
        title={title}
        onCancel={onClose}
        open={open}
        closeIcon={closeIcon}
        style={{
          minWidth: '95%',
          marginTop: '-90px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
          maxWidth: maxWidth,
        }}
        footer={footer}
        maskClosable={false}
        
      >
        {children}
      </S.Modal>
    </>
  );
};
