import axios, { Axios, AxiosError } from 'axios';
import { deleteToken, readToken } from './sessionStorage.service';
import { notificationController } from '@app/controllers/notificationController';

export interface IApiServiceBase<TRequest, TResponse> {
  api: Axios;
  post(endpoint: string, entity: TRequest): Promise<TResponse>;
  postArray(endpoint: string, entity: TRequest[]): Promise<TResponse[]>;
  update(endpoint: string, entity: TRequest): Promise<TResponse>;
  updateArray(endpoint: string, entity: TRequest[]): Promise<TResponse[]>;
  get(endpoint: string): Promise<TResponse>;
  getArray(endpoint: string): Promise<TResponse[]>;
  delete(endpoint: string): Promise<any>;
}

const token = readToken() || localStorage.getItem('accessToken');
export abstract class ApiServiceBase<TRequest, TResponse> implements IApiServiceBase<TRequest, TResponse> {
  public api: Axios;
  private base_url: string;
  private service_endpoint: string;
  constructor(service_endpoint: string) {
    this.service_endpoint = service_endpoint;
    this.base_url = process.env.REACT_APP_BASE_URL || '';

    this.api = axios.create({
      baseURL: `${this.base_url}/${this.service_endpoint}`,
      headers: { 'Content-Type': 'application/json' },
    });


    this.api.interceptors.request.use((config) => {
      config.headers = { ...config.headers, Authorization: `Bearer ${token}` };

      return config;
    });

    this.api.interceptors.response.use(undefined, (error: AxiosError) => {


      if(error.response === undefined){
        return;
      }
      
      if (error.response?.status === 401) {
        window.location.href = 'auth/login';
        /*deleteToken();
        window.location.reload();
        return;*/
      }

      if (error.response?.status === 400) {
       // window.location.href = 'error';
        //notificationController.error({
        //  message: "An error ocurred and the server can't process or recognize the request, please contact the support",
        //});
        return error.response;
      }

      if (error.response?.status === 403) {
       // window.location.href = '403';
        notificationController.error({
          message: "Forbidden, appearly you don't have access to this resource, please contact the support",
        });
        //return;
      }

      if (error.response?.status == 404) {
        //window.location.href = '404';
        return notificationController.error({
          message: "An error ocurred and the resource couldn't be found on the server, please contact the support",
        });
       // return;
      }

      if (error.response?.status === 500) {
        //window.location.href = '500';
        notificationController.error({
          message: 'Internal server error, please contact the support',
        });
        //return;
      }
/*
      throw Error(
        error.response?.data ? error.response?.data : 'Error on consulting the API, please contact an administrator',
      );*/
    });
  }

  async post(endpoint: string, entity: TRequest): Promise<TResponse> {
    try {
      return (await this.api.post(endpoint, JSON.stringify(entity)))?.data;
    } catch (error) {
      throw error;
    }
  }

  async postArray(endpoint: string, entity: TRequest[]): Promise<TResponse[]> {
    try {
      return (await this.api.post(endpoint, JSON.stringify(entity))).data;
    } catch (error) {
      throw error;
    }
  }

  async update(endpoint: string, entity: TRequest): Promise<TResponse> {
    try {
      return (await this.api.put(endpoint, JSON.stringify(entity)))?.data;
    } catch (error) {
      throw error;
    }
  }

  async updateArray(endpoint: string, entity: TRequest[]): Promise<TResponse[]> {
    try {
      return (await this.api.post(endpoint, JSON.stringify(entity)))?.data;
    } catch (error) {
      throw error;
    }
  }

  async get(endpoint: string): Promise<TResponse> {
    try {
      const response = await this.api.get(endpoint);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async getArray(endpoint: string): Promise<TResponse[]> {
    try {
      const response = await this.api.get(endpoint);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async delete(endpoint: string): Promise<any> {
    try {
      return (await this.api.delete(endpoint));
    } catch (error) {
      throw error;
    }
  }
}