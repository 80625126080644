import styled from 'styled-components';
import { Modal as AntDrawer } from 'antd';
import { PRIMARY_COLOR_THEME } from '@app/styles/themes/constants';

export const Modal = styled(AntDrawer)`
  .ant-modal-title {
    color: white;
    font-size: 1.3rem;
    font-weight: bold;
  }

  .ant-modal-header {
    background-color: ${PRIMARY_COLOR_THEME};
  }

  .anticon-close {
    display: flex;
    color: red;
    font-weight: bold;
  }

  .anticon-close:hover {
    display: flex;
    color: var(--text-sider-primary-color);
  }

  .anticon-close-circle {
    color: white;
  }

  .anticon-close-circle:hover {
    color: var(--text-sider-primary-color);
  }
`;
