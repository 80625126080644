import React from 'react';
import * as S from './References.styles';

export const References: React.FC = () => {
  return (
    <S.ReferencesWrapper>
      <S.Text>
          Powered by{' '}
        <a href="https://hagahnet.com/" target="_blank" rel="noreferrer">
          HagahNet{' '}
        </a>
        in {new Date().getFullYear()} &copy;.
      </S.Text>
      <S.Icons></S.Icons>
    </S.ReferencesWrapper>
  );
};