import axios, { AxiosError } from 'axios';
import { deleteToken, readToken } from './sessionStorage.service';
import { notificationController } from '@app/controllers/notificationController';




const axiosService = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || '',
  headers: {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  },
});

axiosService.interceptors.request.use((config) => {
  
  const token = readToken() || localStorage.getItem('accessToken');
  if(!token){
    console.log('no token');
  }
  config.headers = { ...config.headers, Authorization: `Bearer ${token}` };

  return config;
})

axiosService.interceptors.response.use(undefined, (error: AxiosError) => {

  if(error.response === undefined){
    return;
  }

  if (error.response?.status === 401) {
    //window.location.href = '401';
    window.location.href = 'auth/login';
   /* deleteToken();
    window.location.reload();
    return;*/
  }

  if (error.response?.status === 400) {
    //window.location.href = 'error';
    notificationController.error({
      message: "An error ocurred and the server can't process or recognize the request, please contact the support",
    });
    //return;
  }

  if (error.response?.status === 403) {
    //window.location.href = '403';
    notificationController.error({
      message: "Forbidden, appearly you don't have access to this resource, please contact the support",
    });
   // return;
  }

  if (error.response?.status == 404) {
    //window.location.href = '404';
    return notificationController.error({
      message: "An error ocurred and the resource couldn't be found on the server, please contact the support",
    });
    //return;
  }

  if (error.response?.status === 500) {
    //window.location.href = '500';
    notificationController.error({
      message: 'Internal server error, please contact the support',
    });
   // return;
  }

  
/*
  throw Error(
    error.response?.data ? error.response?.data : 'Error on consulting the API, please contact an administrator',
  );*/
});

export default axiosService;